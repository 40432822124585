<template>
  <div>
    dashboard {{ version }}
    <!-- <qr-reader :qrbox="250" :fps="10" style="width: 500px" @result="onScan" /> -->
  </div>
</template>

<script>
// import qrReader from '../components/tools/qrReader.vue';

export default {
  // components: { qrReader },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    onScan(decodedText, decodedResult) {
      // handle the message here :)
      console.log(decodedText, decodedResult);
    },
  },
};
</script>
